import { useStyles } from '@team-griffin/stylr';
import { gu2px } from '@ux/theme-utils';
import { getTitleColor } from 'presentation/utils/colorway';
import { percentage } from '@ux/style-utils';
//@ts-ignore
import cssSides, { Side } from '@team-griffin/css-sides';
import { InnerProps } from './DomainSearchStartStrip';
import { CSSProperties } from 'react';

export interface Styles {
  content: CSSProperties,
  title: CSSProperties,
  search: CSSProperties,
}
export default (props: InnerProps) => {
  const {
    theme,
    colorway,
    isMinMd,
    isMinLg,
  } = props;

  const {
    spacing,
  } = theme;

  return useStyles({
    default: {
      content: {
        ...cssSides('padding', Side.A, gu2px(3, spacing)),
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
      },
      title: {
        textAlign: 'center',
        marginBottom: gu2px(3, spacing),
        color: getTitleColor(theme, colorway),
      },
    },
    isMinMd: {
      content: {
        alignItems: 'center',
      },
      search: {
        flex: 1,
        flexBasis: 'auto', // IE 11 fix
        minWidth: percentage((100/12) * 8),
        ...cssSides('margin', Side.X, 'auto'),
      },
    },
    isMinLg: {
      content: {
        // 2.5 + 1.5 = 4GU padding to implement padding-box rules
        ...cssSides('padding', Side.X, gu2px(2.5, spacing)),
        ...cssSides('padding', Side.Y, gu2px(2.5, spacing)),
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
      },
      title: {
        whiteSpace: 'no-wrap',
        ...cssSides('margin', Side.A, gu2px(1.5, spacing)),
      },
      search: {
        ...cssSides('margin', Side.A, gu2px(1.5, spacing)),
        flex: 1,
        //min-width is 4 col
        minWidth: percentage((100/12) * 4),
        maxWidth: percentage(50),
      },
    },
  }, [
    { isMinMd },
    { isMinLg },
  ]);
};