import React from 'react';
import pureEnhance from '@ux/pure-enhance';
import { ReduxFormSearchInput } from '@ux/search-input';
import DebouncedField from 'redux-form-debounce-field';
import { Theme } from '@ux/theme-utils';
import { InjectedFormProps } from 'redux-form';

interface OuterProps {
  search: {
    tld: string,
    glyph: string,
    label: string,
    placeholder?: string,
    destination?: string,
  },
  form: InjectedFormProps,
  theme: Theme,
}
export interface InnerProps extends OuterProps {}

const DomainSearchForm = ({
  search,
  form,
  theme,
}: InnerProps) => {
  const {
    tld,
    glyph,
    label,
    placeholder,
  } = search;
  return (
    <form
      method="POST"
      onSubmit={form.handleSubmit}
      data-selector="domain-search-form"
    >
      <DebouncedField
        name="domain"
        suffix={tld}
        inputProps={{
          placeholder,
        }}
        button={{
          text: label,
          glyph: glyph,
        }}
        theme={theme}
        //@ts-ignore
        component={ReduxFormSearchInput}
      />
    </form>
  );
};

const enhance = pureEnhance('SearchForm');

export default enhance(DomainSearchForm);