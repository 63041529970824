import React from 'react';
import pureEnhance from '@ux/pure-enhance';
import errorBoundary from 'presentation/hocs/errorBoundary';
import Ribbon from '../Ribbon';
import stylesheet, { Styles } from './DomainSearchStartStrip.stylesheet';
import visibilitySet from 'presentation/hocs/visibilitySet';
import Shortcode, { ShortcodeProps } from 'components/Shortcode';
import { withBreakpointsHook, BreakpointProps } from '@ux/responsive';
import {
  withProps,
} from '@team-griffin/rehook';
import {
  Heading04,
} from '@ux/typography';
import { Container } from '@ux/grid';
import DomainSearchForm from './DomainSearchForm';
import { RibbonProps } from 'domain/constants/ribbons';
import { InjectedFormProps } from 'redux-form';


export interface OuterProps extends RibbonProps {
  text?: {
    title?: ShortcodeProps,
  },
  search: {
    tld: string,
    glyph: string,
    label: string,
    placeholder?: string,
    destination?: string,
  },
  form: InjectedFormProps,
}
export interface InnerProps extends OuterProps, BreakpointProps {
  styles: Styles,
}

const PureDomainSearchStartStrip = ({
  id,
  styles,
  theme,
  loading,
  colorway,
  layout,
  hBaseline,
  name,
  text,
  search,
  form,
}: InnerProps) => {
  const {
    title,
  } = text;
  return (
    <Ribbon
      id={id}
      theme={theme}
      name={name}
      layout={layout}
      colorway={colorway}
      loading={loading}
      flush={true}
      type="DomainSearchStartStrip"
    >
      <Container>
        <div style={styles.content}>
          <Heading04
            theme={theme}
            depth={hBaseline}
            style={styles.title}
          >
            <Shortcode
              theme={theme}
              against={colorway}
              code={title}
            />
          </Heading04>
          <div style={styles.search}>
            <DomainSearchForm
              search={search}
              form={form}
              theme={theme}
            />
          </div>
        </div>
      </Container>
    </Ribbon>
  );
};

const enhance = pureEnhance<InnerProps, OuterProps>(
  'DomainSearchStartStrip',
  {
    hocs: [
      errorBoundary,
      visibilitySet,
    ],
    hooks: [
      withBreakpointsHook,
      withProps(stylesheet),
    ],
  }
);

export default enhance(PureDomainSearchStartStrip);
