import {
  withProps,
  withHandlers,
} from '@team-griffin/rehook';
import {
  connect,
} from '@ux/fabric';
import * as r from 'ramda';
import DomainSearchStartStrip, { OuterProps as PureProps } from './DomainSearchStartStrip';
import { submit } from 'application/signals/ribbons/domain-search-start';
import { InjectedFormProps, reduxForm } from 'redux-form';
import pureEnhance, { withHooks } from '@ux/pure-enhance';

interface ConnectedProps {
  form: InjectedFormProps,
}
interface InnerProps extends PureProps {}
interface OuterProps extends Omit<InnerProps, keyof ConnectedProps> {}


const createFormName = r.pipe(
  r.prop('id'),
  //@ts-ignore
  r.append(r.__, [ 'usf', 'domainSearchStartStrip' ]),
  r.join('.'),
  r.objOf('form'),
);

//@ts-ignore
const mapStateToProps = void 0;

const mapDispatchToProps = {
  submit,
};


const enhance = pureEnhance<InnerProps, OuterProps>(
  'ConnectedDomainSearchStartStrip',
  {
    pipe: [
      withHooks([
        withProps(connect(mapStateToProps, mapDispatchToProps)),
        withProps(createFormName),
        withHandlers({
          onSubmit: ({
            actions: {
              submit,
            },
            search: {
              tld,
              destination,
            },
            form,
            name,
          }: {
            actions: {
              submit: Function,
            },
            search: {
              tld: string,
              destination: string,
            },
            form: InjectedFormProps,
            name: string,
          }) => ({ domain }: {
            domain: string,
          }) => {
            return new Promise(() => {
              submit({
                domain,
                tld,
                destination,
                form,
                ribbonType: 'DomainSearchStartStrip',
                ribbonName: name,
              });
            });
          },
        }),
      ]),
      reduxForm({
        propNamespace: 'form',
      }),
    ],
  },
);

export default enhance(DomainSearchStartStrip);
